import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import ProjectHighlight from '../components/project-highlight'
import ContactForm from '../components/contact-form'
import Footer from '../components/footer'
import './pallette.css'

const Pallette = (props) => {
  return (
    <div className="pallette-container10">
      <Helmet>
        <title>Pallette - Craft & Forge - Premium Home Remodeling</title>
        <meta
          name="description"
          content="Craft &amp; Forge offers high-end remodeling with a seamless process from consultation to completion. Discover expert craftsmanship and stunning transformations."
        />
        <meta
          property="og:title"
          content="Pallette - Craft &amp; Forge - Premium Home Remodeling"
        />
        <meta
          property="og:description"
          content="Craft &amp; Forge offers high-end remodeling with a seamless process from consultation to completion. Discover expert craftsmanship and stunning transformations."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/133b66a8-437a-4fe1-8938-2c01fd5c558d/c7ed6f96-1e17-4876-a525-ef1747a6b890?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navbar imageSrc1="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"></Navbar>
      <div className="pallette-divider1">
        <span className="pallette-div">Hero</span>
      </div>
      <div className="pallette-hero-fade1">
        <div className="pallette-hero1">
          <div className="pallette-content-container1">
            <animate-on-reveal
              animation="fadeInUp"
              duration="1000ms"
              delay="0s"
              direction="normal"
              easing="ease-out"
              iteration="1"
            >
              <div
                data-thq-animate-on-reveal="true"
                className="pallette-max-width10"
              >
                <div className="pallette-heading-container10">
                  <span className="pallette-prehead10">Craft &amp; Forge</span>
                  <span className="pallette-prehead11">
                    Craft &amp; Forge, previously Northwest unlimited
                  </span>
                  <span className="pallette-main-title1 Title">
                    <span>crafting homes,</span>
                    <br></br>
                    <span>forging futures</span>
                  </span>
                  <span className="pallette-text104">
                    <span>
                      At Craft &amp; Forge Homes, we build homes and lasting
                      relationships. Every project is
                    </span>
                    <br></br>
                    <span>
                      a partnership built on trust, where thoughtful design and
                      meticulous craftsmanship
                    </span>
                    <br></br>
                    <span>
                      {' '}
                      bring your vision to life, creating spaces and connections
                      that last a lifetime.
                    </span>
                  </span>
                  <span className="pallette-text110">
                    At Craft &amp; Forge Homes, we build homes and lasting
                    relationships. Every project is a partnership built on
                    trust, where thoughtful design and meticulous craftsmanship
                    bring your vision to life, creating spaces and connections
                    that last a lifetime.
                  </span>
                  <a
                    href="#Contact-form"
                    className="pallette-link1 Content bluebutton"
                  >
                    <span>Bring your vision to life</span>
                  </a>
                </div>
              </div>
            </animate-on-reveal>
          </div>
        </div>
      </div>
      <div className="pallette-banner">
        <div className="pallette-hero2">
          <div className="pallette-content-container2">
            <animate-on-reveal
              animation="fadeInUp"
              duration="1000ms"
              delay="0s"
              direction="normal"
              easing="ease-out"
              iteration="1"
            >
              <div
                data-thq-animate-on-reveal="true"
                className="pallette-max-width11"
              >
                <div className="pallette-heading-container11">
                  <span className="pallette-prehead12">about us</span>
                  <span className="pallette-prehead13">
                    Craft &amp; Forge, previously Northwest unlimited
                  </span>
                  <span className="pallette-main-title2">
                    <span>
                      our passion
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <br></br>
                    <span>for precision</span>
                  </span>
                </div>
              </div>
            </animate-on-reveal>
          </div>
        </div>
      </div>
      <div className="pallette-hero-fade2">
        <div className="pallette-hero3">
          <div className="pallette-max-width12">
            <div className="pallette-container11">
              <span className="pallette-prehead14">
                <span>connect with us</span>
                <br></br>
              </span>
              <h1 className="pallette-text117 Heading">
                Bring your vision to life
              </h1>
              <span className="pallette-text118">
                <span>
                  We’re excited to hear about your project! Whether you have a
                </span>
                <br></br>
                <span>
                  clear vision
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  or need expert guidance, Craft &amp; Forge is here to
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>
                  make your remodeling journey
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>seamless and enjoyable.</span>
              </span>
              <span className="pallette-text126">
                <span>
                  We’re excited to hear about your project! Whether you have a
                  clear vision 
                </span>
                <span>
                  or need expert guidance, Craft &amp; Forge is here to make
                  your remodeling journey 
                </span>
                <span>seamless and enjoyable. Contact us to get started.</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="pallette-hero-fade-single">
        <div className="pallette-hero4">
          <div className="pallette-content-container3">
            <animate-on-reveal
              animation="fadeInUp"
              duration="1000ms"
              delay="0s"
              direction="normal"
              easing="ease-out"
              iteration="1"
            >
              <div
                data-thq-animate-on-reveal="true"
                className="pallette-max-width13"
              >
                <div className="pallette-heading-container12">
                  <span className="pallette-prehead15">about us</span>
                  <span className="pallette-prehead16">
                    Craft &amp; Forge, previously Northwest unlimited
                  </span>
                  <span className="pallette-main-title3 Title">
                    <span>
                      our passion
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <br></br>
                    <span>for precision</span>
                  </span>
                </div>
              </div>
            </animate-on-reveal>
          </div>
        </div>
      </div>
      <div className="pallette-hero-fade-center">
        <div className="pallette-hero5">
          <div className="pallette-content-container4">
            <animate-on-reveal
              animation="fadeInUp"
              duration="1000ms"
              delay="0s"
              direction="normal"
              easing="ease-out"
              iteration="1"
            >
              <div
                data-thq-animate-on-reveal="true"
                className="pallette-max-width14"
              >
                <div className="pallette-heading-container13">
                  <span className="pallette-prehead17">Craft &amp; Forge</span>
                  <span className="pallette-prehead18">
                    Craft &amp; Forge, previously Northwest unlimited
                  </span>
                  <span className="pallette-main-title4 Title">
                    <span>crafting homes,</span>
                    <br></br>
                    <span>forging futures</span>
                  </span>
                  <span className="pallette-text136">
                    <span>
                      At Craft &amp; Forge Homes, we build homes and lasting
                      relationships. Every project is
                    </span>
                    <br></br>
                    <span>
                      a partnership built on trust, where thoughtful design and
                      meticulous craftsmanship
                    </span>
                    <br></br>
                    <span>
                      {' '}
                      bring your vision to life, creating spaces and connections
                      that last a lifetime.
                    </span>
                  </span>
                  <span className="pallette-text142">
                    At Craft &amp; Forge Homes, we build homes and lasting
                    relationships. Every project is a partnership built on
                    trust, where thoughtful design and meticulous craftsmanship
                    bring your vision to life, creating spaces and connections
                    that last a lifetime.
                  </span>
                  <a
                    href="#Contact-form"
                    className="pallette-link2 bluebutton Content"
                  >
                    <span>Bring your vision to life</span>
                  </a>
                </div>
              </div>
            </animate-on-reveal>
          </div>
        </div>
      </div>
      <div className="pallette-divider2">
        <span className="pallette-div">value prop</span>
      </div>
      <div className="pallette-meet-the-founder-page-cut">
        <div className="pallette-gradient-container1"></div>
        <div className="pallette-blur-container">
          <div className="pallette-max-width15">
            <div className="pallette-container12">
              <div className="pallette-heading-container14">
                <span className="pallette-prehead19">Our founder</span>
                <h1 className="pallette-text145 SectionHeading">
                  <span>Crafting a Legacy</span>
                  <br></br>
                  <span>of excellence</span>
                </h1>
                <h1 className="pallette-text149 SectionHeading">
                  <span>
                    Crafting a Legacy
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>of excellence</span>
                </h1>
              </div>
              <div className="pallette-content1">
                <div className="pallette-line"></div>
                <div className="pallette-section-title">
                  <h2 className="pallette-text152">
                    <span className="Subheading">Dayne Richards</span>
                    <br></br>
                  </h2>
                  <p className="pallette-text155 Content">
                    <span>
                      With over 20 years of experience in the remodeling
                      industry, Dayne founded Craft &amp; Forge to bring a new
                      level of excellence to home renovation. His dedication to
                      modern design and meticulous craftsmanship has transformed
                      countless homes into architectural masterpieces.
                    </span>
                    <br></br>
                    <br></br>
                    <span>
                      &quot;For me, home has always been a place where my values
                      need to be represented. Having a beautiful home could be
                      chalked up to vanity, but it so much more than that. We
                      spend so much time, energy, and money on our homes. Why
                      shouldn’t it be something that truly reflects us and is a
                      place we are proud of.&quot;
                    </span>
                    <br></br>
                  </p>
                </div>
              </div>
            </div>
            <img
              alt="Craft &amp; Forge Features"
              src="/photos/home/dayne%20head%20mixed2-1000w.png"
              className="pallette-image1"
            />
          </div>
        </div>
        <div className="pallette-gradient-container2"></div>
      </div>
      <div className="pallette-mission">
        <div className="pallette-max-width16">
          <div className="pallette-image10"></div>
          <div className="pallette-float1">
            <div className="pallette-content2">
              <span className="pallette-prehead20">our mission</span>
              <span className="pallette-text161 SectionHeading">
                designing with purpose
              </span>
              <span className="pallette-text162 Content">
                <span>
                  Our vision is to create homes that are more than just spaces,
                  they are reflections of the people who live in them. We
                  believe in designing with integrity, ensuring that every
                  detail, visible or hidden, is crafted to the highest standard.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
              </span>
              <span className="pallette-text165 Content">
                By fostering genuine relationships with our clients, we turn
                their visions into reality, making every home a place of pride
                and beauty. Through passion, precision, and commitment, we seek
                to redefine what it means to feel truly at home.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="pallette-mission-reverse">
        <div className="pallette-max-width17">
          <div className="pallette-image11"></div>
          <div className="pallette-float2">
            <div className="pallette-content3">
              <span className="pallette-prehead21">our mission</span>
              <span className="pallette-text166 SectionHeading">
                designing with purpose
              </span>
              <span className="pallette-text167 Content">
                <span>
                  Our vision is to create homes that are more than just spaces,
                  they are reflections of the people who live in them. We
                  believe in designing with integrity, ensuring that every
                  detail, visible or hidden, is crafted to the highest standard.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
              </span>
              <span className="pallette-text170 Content">
                By fostering genuine relationships with our clients, we turn
                their visions into reality, making every home a place of pride
                and beauty. Through passion, precision, and commitment, we seek
                to redefine what it means to feel truly at home.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="pallette-mission-small">
        <div className="pallette-max-width18">
          <div className="pallette-content4">
            <span className="pallette-prehead22">our mission</span>
            <span className="pallette-text171 SectionHeading">
              designing with purpose
            </span>
            <span className="pallette-text172 Content">
              <span>
                Our vision is to create homes that are more than just spaces,
                they are reflections of the people who live in them. We believe
                in designing with integrity, ensuring that every detail, visible
                or hidden, is crafted to the highest standard.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
            </span>
            <span className="pallette-text175 Content">
              By fostering genuine relationships with our clients, we turn their
              visions into reality, making every home a place of pride and
              beauty. Through passion, precision, and commitment, we seek to
              redefine what it means to feel truly at home.
            </span>
          </div>
          <div className="pallette-image12"></div>
        </div>
      </div>
      <div className="pallette-mission-small-cover1">
        <div className="pallette-max-width19">
          <div className="pallette-image13"></div>
          <div className="pallette-content5">
            <span className="pallette-prehead23">our mission</span>
            <span className="pallette-text176 SectionHeading">
              designing with purpose
            </span>
            <span className="pallette-text177 Content">
              <span>
                Our vision is to create homes that are more than just spaces,
                they are reflections of the people who live in them. We believe
                in designing with integrity, ensuring that every detail, visible
                or hidden, is crafted to the highest standard.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
            </span>
            <span className="pallette-text180 Content">
              By fostering genuine relationships with our clients, we turn their
              visions into reality, making every home a place of pride and
              beauty. Through passion, precision, and commitment, we seek to
              redefine what it means to feel truly at home.
            </span>
          </div>
        </div>
      </div>
      <div className="pallette-mission-cover">
        <div className="pallette-max-width20">
          <div className="pallette-image14"></div>
          <div className="pallette-content6">
            <span className="pallette-prehead24">our mission</span>
            <span className="pallette-text181 SectionHeading">
              designing with purpose
            </span>
            <span className="pallette-text182 Content">
              <span>
                Our vision is to create homes that are more than just spaces,
                they are reflections of the people who live in them. We believe
                in designing with integrity, ensuring that every detail, visible
                or hidden, is crafted to the highest standard.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
            </span>
            <span className="pallette-text185 Content">
              By fostering genuine relationships with our clients, we turn their
              visions into reality, making every home a place of pride and
              beauty. Through passion, precision, and commitment, we seek to
              redefine what it means to feel truly at home.
            </span>
          </div>
        </div>
      </div>
      <div className="pallette-mission-cover-dark">
        <div className="pallette-max-width21">
          <div className="pallette-image15"></div>
          <div className="pallette-content7">
            <span className="pallette-prehead25">our mission</span>
            <span className="pallette-text186 SectionHeading">
              designing with purpose
            </span>
            <span className="pallette-text187 Content">
              <span>
                Our vision is to create homes that are more than just spaces,
                they are reflections of the people who live in them. We believe
                in designing with integrity, ensuring that every detail, visible
                or hidden, is crafted to the highest standard.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
            </span>
            <span className="pallette-text190 Content">
              By fostering genuine relationships with our clients, we turn their
              visions into reality, making every home a place of pride and
              beauty. Through passion, precision, and commitment, we seek to
              redefine what it means to feel truly at home.
            </span>
          </div>
        </div>
      </div>
      <div className="pallette-mission-cascade">
        <div className="pallette-max-width22">
          <div className="pallette-image16"></div>
          <div className="pallette-image17"></div>
          <div className="pallette-image18"></div>
          <div className="pallette-content8">
            <span className="pallette-prehead26">our mission</span>
            <span className="pallette-text191 SectionHeading">
              designing with purpose
            </span>
            <span className="pallette-text192 Content">
              <span>
                Our vision is to create homes that are more than just spaces,
                they are reflections of the people who live in them. We believe
                in designing with integrity, ensuring that every detail, visible
                or hidden, is crafted to the highest standard.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
            </span>
            <span className="pallette-text195 Content">
              By fostering genuine relationships with our clients, we turn their
              visions into reality, making every home a place of pride and
              beauty. Through passion, precision, and commitment, we seek to
              redefine what it means to feel truly at home.
            </span>
          </div>
        </div>
      </div>
      <div className="pallette-divider3">
        <span className="pallette-div">Layered Value prop</span>
      </div>
      <div className="pallette-mission-layered">
        <div className="pallette-heading1">
          <span className="pallette-prehead27">our mission</span>
          <span className="pallette-text197 SectionHeading">
            designing with purpose
          </span>
        </div>
        <div className="pallette-row10">
          <div className="pallette-image19"></div>
          <div className="pallette-container13">
            <div className="pallette-text198">
              <span className="pallette-title10 team-title">
                founding partner
              </span>
              <span className="pallette-text199">Dayne Richards</span>
              <span className="pallette-bio10 copy">
                As CEO, Dayne brings expertise in construction logistics and
                project management, ensuring every remodel is completed with
                precision. His hands-on approach keeps projects on track,
                driving Craft &amp; Forge&apos;s exceptional results
              </span>
            </div>
          </div>
        </div>
        <div className="pallette-row11">
          <div className="pallette-image20"></div>
          <div className="pallette-container14">
            <div className="pallette-text200">
              <span className="pallette-title11 team-title">
                founding partner
              </span>
              <span className="pallette-text201">Dayne Richards</span>
              <span className="pallette-bio11 copy">
                As CEO, Dayne brings expertise in construction logistics and
                project management, ensuring every remodel is completed with
                precision. His hands-on approach keeps projects on track,
                driving Craft &amp; Forge&apos;s exceptional results
              </span>
            </div>
          </div>
        </div>
        <div className="pallette-row12">
          <div className="pallette-image21"></div>
          <div className="pallette-container15">
            <div className="pallette-text202">
              <span className="pallette-title12 team-title">
                founding partner
              </span>
              <span className="pallette-text203">Dayne Richards</span>
              <span className="pallette-bio12 copy">
                As CEO, Dayne brings expertise in construction logistics and
                project management, ensuring every remodel is completed with
                precision. His hands-on approach keeps projects on track,
                driving Craft &amp; Forge&apos;s exceptional results
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="pallette-mission-layered2">
        <div className="pallette-heading2">
          <span className="pallette-prehead28">our mission</span>
          <span className="pallette-text204 SectionHeading">
            designing with purpose
          </span>
        </div>
        <div className="pallette-row13">
          <div className="pallette-image22"></div>
          <div className="pallette-container16">
            <div className="pallette-text205">
              <span className="pallette-title13 team-title">
                founding partner
              </span>
              <span className="pallette-text206">Dayne Richards</span>
              <span className="pallette-bio13 copy">
                As CEO, Dayne brings expertise in construction logistics and
                project management, ensuring every remodel is completed with
                precision. His hands-on approach keeps projects on track,
                driving Craft &amp; Forge&apos;s exceptional results
              </span>
            </div>
          </div>
        </div>
        <div className="pallette-row14">
          <div className="pallette-image23"></div>
          <div className="pallette-container17">
            <div className="pallette-text207">
              <span className="pallette-title14 team-title">
                founding partner
              </span>
              <span className="pallette-text208">Dayne Richards</span>
              <span className="pallette-bio14 copy">
                As CEO, Dayne brings expertise in construction logistics and
                project management, ensuring every remodel is completed with
                precision. His hands-on approach keeps projects on track,
                driving Craft &amp; Forge&apos;s exceptional results
              </span>
            </div>
          </div>
        </div>
        <div className="pallette-row15">
          <div className="pallette-image24"></div>
          <div className="pallette-container18">
            <div className="pallette-text209">
              <span className="pallette-title15 team-title">
                founding partner
              </span>
              <span className="pallette-text210">Dayne Richards</span>
              <span className="pallette-bio15 copy">
                As CEO, Dayne brings expertise in construction logistics and
                project management, ensuring every remodel is completed with
                precision. His hands-on approach keeps projects on track,
                driving Craft &amp; Forge&apos;s exceptional results
              </span>
            </div>
          </div>
        </div>
        <div className="pallette-row16">
          <div className="pallette-image25"></div>
          <div className="pallette-container19">
            <div className="pallette-text211">
              <span className="pallette-title16 team-title">
                founding partner
              </span>
              <span className="pallette-text212">Dayne Richards</span>
              <span className="pallette-bio16 copy">
                As CEO, Dayne brings expertise in construction logistics and
                project management, ensuring every remodel is completed with
                precision. His hands-on approach keeps projects on track,
                driving Craft &amp; Forge&apos;s exceptional results
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="pallette-mission-layered3">
        <div className="pallette-heading3">
          <span className="pallette-prehead29">our mission</span>
          <span className="pallette-text213 SectionHeading">
            designing with purpose
          </span>
        </div>
        <div className="pallette-row17">
          <div className="pallette-image26"></div>
          <div className="pallette-container20">
            <div className="pallette-text214">
              <span className="pallette-title17 team-title">
                founding partner
              </span>
              <span className="pallette-text215">Dayne Richards</span>
              <span className="pallette-bio17 copy">
                As CEO, Dayne brings expertise in construction logistics and
                project management, ensuring every remodel is completed with
                precision. His hands-on approach keeps projects on track,
                driving Craft &amp; Forge&apos;s exceptional results
              </span>
            </div>
          </div>
        </div>
        <div className="pallette-row18">
          <div className="pallette-image27"></div>
          <div className="pallette-container21">
            <div className="pallette-text216">
              <span className="pallette-title18 team-title">
                founding partner
              </span>
              <span className="pallette-text217">Dayne Richards</span>
              <span className="pallette-bio18 copy">
                As CEO, Dayne brings expertise in construction logistics and
                project management, ensuring every remodel is completed with
                precision. His hands-on approach keeps projects on track,
                driving Craft &amp; Forge&apos;s exceptional results
              </span>
            </div>
          </div>
        </div>
        <div className="pallette-row19">
          <div className="pallette-image28"></div>
          <div className="pallette-container22">
            <div className="pallette-text218">
              <span className="pallette-title19 team-title">
                founding partner
              </span>
              <span className="pallette-text219">Dayne Richards</span>
              <span className="pallette-bio19 copy">
                As CEO, Dayne brings expertise in construction logistics and
                project management, ensuring every remodel is completed with
                precision. His hands-on approach keeps projects on track,
                driving Craft &amp; Forge&apos;s exceptional results
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="pallette-mission-layered4">
        <div className="pallette-container23">
          <div className="pallette-collumn1">
            <div className="pallette-item10">
              <div className="pallette-image29"></div>
              <div className="pallette-container24">
                <div className="pallette-text220">
                  <span className="pallette-title20 team-title">
                    founding partner
                  </span>
                  <span className="pallette-text221">Dayne Richards</span>
                  <span className="pallette-bio20 copy">
                    As CEO, Dayne brings expertise in construction logistics and
                    project management, ensuring every remodel is completed with
                    precision. His hands-on approach keeps projects on track,
                    driving Craft &amp; Forge&apos;s exceptional results
                  </span>
                </div>
              </div>
            </div>
            <div className="pallette-item11">
              <div className="pallette-image30"></div>
              <div className="pallette-container25">
                <div className="pallette-text222">
                  <span className="pallette-title21 team-title">
                    founding partner
                  </span>
                  <span className="pallette-text223">Dayne Richards</span>
                  <span className="pallette-bio21 copy">
                    As CEO, Dayne brings expertise in construction logistics and
                    project management, ensuring every remodel is completed with
                    precision. His hands-on approach keeps projects on track,
                    driving Craft &amp; Forge&apos;s exceptional results
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="pallette-collumn2">
            <div className="pallette-heading4">
              <span className="pallette-prehead30">our mission</span>
              <span className="pallette-text224 SectionHeading">
                designing with purpose
              </span>
            </div>
            <div className="pallette-item12">
              <div className="pallette-image31"></div>
              <div className="pallette-container26">
                <div className="pallette-text225">
                  <span className="pallette-title22 team-title">
                    founding partner
                  </span>
                  <span className="pallette-text226">Dayne Richards</span>
                  <span className="pallette-bio22 copy">
                    As CEO, Dayne brings expertise in construction logistics and
                    project management, ensuring every remodel is completed with
                    precision. His hands-on approach keeps projects on track,
                    driving Craft &amp; Forge&apos;s exceptional results
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pallette-team">
        <div className="pallette-heading-container15">
          <span className="pallette-prehead31 Prehead">Our team</span>
          <h1 className="pallette-text227 SectionHeading">
            behind every great build
          </h1>
        </div>
        <div className="pallette-team-container">
          <div className="pallette-collumn3">
            <div className="pallette-person1">
              <img
                alt="image"
                src="/photos/about/dayne%20cropped1.png"
                className="pallette-image32"
              />
              <div className="pallette-text228">
                <span className="pallette-title23 team-title">
                  founding partner
                </span>
                <span className="pallette-text229">Dayne Richards</span>
                <span className="pallette-bio23 copy">
                  As CEO, Dayne brings expertise in construction logistics and
                  project management, ensuring every remodel is completed with
                  precision. His hands-on approach keeps projects on track,
                  driving Craft &amp; Forge&apos;s exceptional results
                </span>
              </div>
            </div>
            <div className="pallette-person2">
              <div className="pallette-text230">
                <span className="pallette-title24 team-title">partner</span>
                <span className="pallette-text231">Adam McElwee</span>
                <span className="pallette-bio24 copy">
                  Adam drives Craft &amp; Forge’s business strategy, managing
                  marketing, client outreach, and operations. His expertise in
                  building partnerships and streamlining processes fuels the
                  company’s growth in competitive markets
                </span>
              </div>
              <img
                alt="image"
                src="/photos/about/adam.png"
                className="pallette-image33"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="pallette-divider4">
        <span className="pallette-div">Gallery</span>
      </div>
      <ProjectHighlight></ProjectHighlight>
      <div className="pallette-grid">
        <animate-on-reveal
          animation="fadeIn"
          duration="1000ms"
          delay="0s"
          direction="normal"
          easing="ease-out"
          iteration="1"
        >
          <div
            data-thq-animate-on-reveal="true"
            className="pallette-heading-container16"
          >
            <span className="pallette-prehead32 Prehead">Our Work</span>
            <h1 className="pallette-text233 SectionHeading">
              <span>elevated designs,</span>
              <br></br>
              <span>showcased</span>
            </h1>
          </div>
        </animate-on-reveal>
        <div className="pallette-mission-small-cover2">
          <div className="pallette-row20">
            <div className="pallette-item13">
              <div className="pallette-image34"></div>
              <div className="pallette-details10">
                <span className="pallette-head10 Subheading">
                  {' '}
                  functional kitchen overhaul
                </span>
                <span className="pallette-description10">
                  Featuring crisp white cabinetry and high-end stainless steel
                  appliances, this kitchen exudes modern sophistication. The
                  geometric backsplash adds subtle texture
                </span>
              </div>
            </div>
            <div className="pallette-item14">
              <div className="pallette-image35"></div>
              <div className="pallette-details11">
                <span className="pallette-head11 Subheading">
                  {' '}
                  functional kitchen overhaul
                </span>
                <span className="pallette-description11">
                  Featuring crisp white cabinetry and high-end stainless steel
                  appliances, this kitchen exudes modern sophistication. The
                  geometric backsplash adds subtle texture
                </span>
              </div>
            </div>
            <div className="pallette-item15">
              <div className="pallette-image36"></div>
              <div className="pallette-details12">
                <span className="pallette-head12 Subheading">
                  {' '}
                  functional kitchen overhaul
                </span>
                <span className="pallette-description12">
                  Featuring crisp white cabinetry and high-end stainless steel
                  appliances, this kitchen exudes modern sophistication. The
                  geometric backsplash adds subtle texture
                </span>
              </div>
            </div>
          </div>
          <div className="pallette-row21">
            <div className="pallette-item16">
              <div className="pallette-image37"></div>
              <div className="pallette-details13">
                <span className="pallette-head13 Subheading">
                  {' '}
                  functional kitchen overhaul
                </span>
                <span className="pallette-description13">
                  Featuring crisp white cabinetry and high-end stainless steel
                  appliances, this kitchen exudes modern sophistication. The
                  geometric backsplash adds subtle texture
                </span>
              </div>
            </div>
            <div className="pallette-item17">
              <div className="pallette-image38"></div>
              <div className="pallette-details14">
                <span className="pallette-head14 Subheading">
                  {' '}
                  functional kitchen overhaul
                </span>
                <span className="pallette-description14">
                  Featuring crisp white cabinetry and high-end stainless steel
                  appliances, this kitchen exudes modern sophistication. The
                  geometric backsplash adds subtle texture
                </span>
              </div>
            </div>
            <div className="pallette-item18">
              <div className="pallette-image39"></div>
              <div className="pallette-details15">
                <span className="pallette-head15 Subheading">
                  {' '}
                  functional kitchen overhaul
                </span>
                <span className="pallette-description15">
                  Featuring crisp white cabinetry and high-end stainless steel
                  appliances, this kitchen exudes modern sophistication. The
                  geometric backsplash adds subtle texture
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pallette-chaos-grid">
        <animate-on-reveal
          animation="fadeIn"
          duration="1000ms"
          delay="0s"
          direction="normal"
          easing="ease-out"
          iteration="1"
        >
          <div
            data-thq-animate-on-reveal="true"
            className="pallette-heading-container17"
          >
            <span className="pallette-prehead33 Prehead">Our Work</span>
            <h1 className="pallette-text237 SectionHeading">
              <span>elevated designs,</span>
              <br></br>
              <span>showcased</span>
            </h1>
          </div>
        </animate-on-reveal>
        <div className="pallette-mission-small-cover3">
          <div className="pallette-row22">
            <div className="pallette-item19">
              <div className="pallette-image40"></div>
              <div className="pallette-details16">
                <span className="pallette-head16 Subheading">
                  {' '}
                  functional kitchen overhaul
                </span>
                <span className="pallette-description16">
                  Featuring crisp white cabinetry and high-end stainless steel
                  appliances, this kitchen exudes modern sophistication. The
                  geometric backsplash adds subtle texture
                </span>
              </div>
            </div>
            <div className="pallette-item20">
              <div className="pallette-image41"></div>
              <div className="pallette-details17">
                <span className="pallette-head17 Subheading">
                  {' '}
                  functional kitchen overhaul
                </span>
                <span className="pallette-description17">
                  Featuring crisp white cabinetry and high-end stainless steel
                  appliances, this kitchen exudes modern sophistication. The
                  geometric backsplash adds subtle texture
                </span>
              </div>
            </div>
          </div>
          <div className="pallette-row23">
            <div className="pallette-item21">
              <div className="pallette-image42"></div>
              <div className="pallette-details18">
                <span className="pallette-head18 Subheading">
                  {' '}
                  functional kitchen overhaul
                </span>
                <span className="pallette-description18">
                  Featuring crisp white cabinetry and high-end stainless steel
                  appliances, this kitchen exudes modern sophistication. The
                  geometric backsplash adds subtle texture
                </span>
              </div>
            </div>
            <div className="pallette-item22">
              <div className="pallette-image43"></div>
              <div className="pallette-details19">
                <span className="pallette-head19 Subheading">
                  {' '}
                  functional kitchen overhaul
                </span>
                <span className="pallette-description19">
                  Featuring crisp white cabinetry and high-end stainless steel
                  appliances, this kitchen exudes modern sophistication. The
                  geometric backsplash adds subtle texture
                </span>
              </div>
            </div>
          </div>
          <div className="pallette-row24">
            <div className="pallette-item23">
              <div className="pallette-image44"></div>
              <div className="pallette-details20">
                <span className="pallette-head20 Subheading">
                  {' '}
                  functional kitchen overhaul
                </span>
                <span className="pallette-description20">
                  Featuring crisp white cabinetry and high-end stainless steel
                  appliances, this kitchen exudes modern sophistication. The
                  geometric backsplash adds subtle texture
                </span>
              </div>
            </div>
            <div className="pallette-item24">
              <div className="pallette-image45"></div>
              <div className="pallette-details21">
                <span className="pallette-head21 Subheading">
                  {' '}
                  functional kitchen overhaul
                </span>
                <span className="pallette-description21">
                  Featuring crisp white cabinetry and high-end stainless steel
                  appliances, this kitchen exudes modern sophistication. The
                  geometric backsplash adds subtle texture
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pallette-faded-chaos-grid">
        <animate-on-reveal
          animation="fadeIn"
          duration="1000ms"
          delay="0s"
          direction="normal"
          easing="ease-out"
          iteration="1"
        >
          <div
            data-thq-animate-on-reveal="true"
            className="pallette-heading-container18"
          >
            <span className="pallette-prehead34 Prehead">Our Work</span>
            <h1 className="pallette-text241 SectionHeading">
              <span>elevated designs,</span>
              <br></br>
              <span>showcased</span>
            </h1>
          </div>
        </animate-on-reveal>
        <div className="pallette-mission-small-cover4">
          <div className="pallette-row25">
            <div className="pallette-item25">
              <div className="pallette-image46"></div>
              <div className="pallette-details22">
                <span className="pallette-head22 Subheading">
                  {' '}
                  functional kitchen overhaul
                </span>
                <span className="pallette-description22">
                  Featuring crisp white cabinetry and high-end stainless steel
                  appliances, this kitchen exudes modern sophistication. The
                  geometric backsplash adds subtle texture
                </span>
              </div>
            </div>
            <div className="pallette-item26">
              <div className="pallette-image47"></div>
              <div className="pallette-details23">
                <span className="pallette-head23 Subheading">
                  {' '}
                  functional kitchen overhaul
                </span>
                <span className="pallette-description23">
                  Featuring crisp white cabinetry and high-end stainless steel
                  appliances, this kitchen exudes modern sophistication. The
                  geometric backsplash adds subtle texture
                </span>
              </div>
            </div>
          </div>
          <div className="pallette-row26">
            <div className="pallette-item27">
              <div className="pallette-image48"></div>
              <div className="pallette-details24">
                <span className="pallette-head24 Subheading">
                  {' '}
                  functional kitchen overhaul
                </span>
                <span className="pallette-description24">
                  Featuring crisp white cabinetry and high-end stainless steel
                  appliances, this kitchen exudes modern sophistication. The
                  geometric backsplash adds subtle texture
                </span>
              </div>
            </div>
            <div className="pallette-item28">
              <div className="pallette-image49"></div>
              <div className="pallette-details25">
                <span className="pallette-head25 Subheading">
                  {' '}
                  functional kitchen overhaul
                </span>
                <span className="pallette-description25">
                  Featuring crisp white cabinetry and high-end stainless steel
                  appliances, this kitchen exudes modern sophistication. The
                  geometric backsplash adds subtle texture
                </span>
              </div>
            </div>
          </div>
          <div className="pallette-row27">
            <div className="pallette-item29">
              <div className="pallette-image50"></div>
              <div className="pallette-details26">
                <span className="pallette-head26 Subheading">
                  {' '}
                  functional kitchen overhaul
                </span>
                <span className="pallette-description26">
                  Featuring crisp white cabinetry and high-end stainless steel
                  appliances, this kitchen exudes modern sophistication. The
                  geometric backsplash adds subtle texture
                </span>
              </div>
            </div>
            <div className="pallette-item30">
              <div className="pallette-image51"></div>
              <div className="pallette-details27">
                <span className="pallette-head27 Subheading">
                  {' '}
                  functional kitchen overhaul
                </span>
                <span className="pallette-description27">
                  Featuring crisp white cabinetry and high-end stainless steel
                  appliances, this kitchen exudes modern sophistication. The
                  geometric backsplash adds subtle texture
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pallette-faded-chaos-grid-labeled">
        <animate-on-reveal
          animation="fadeIn"
          duration="1000ms"
          delay="0s"
          direction="normal"
          easing="ease-out"
          iteration="1"
        >
          <div
            data-thq-animate-on-reveal="true"
            className="pallette-heading-container19"
          >
            <span className="pallette-prehead35 Prehead">Our Work</span>
            <h1 className="pallette-text245 SectionHeading">
              <span>elevated designs,</span>
              <br></br>
              <span>showcased</span>
            </h1>
          </div>
        </animate-on-reveal>
        <div className="pallette-mission-small-cover5">
          <div className="pallette-row28">
            <div className="pallette-item31">
              <div className="pallette-image52"></div>
              <div className="pallette-details28">
                <span className="pallette-head28 Subheading">
                  {' '}
                  functional kitchen overhaul
                </span>
              </div>
            </div>
            <div className="pallette-item32">
              <div className="pallette-image53"></div>
              <div className="pallette-details29">
                <span className="pallette-head29 Subheading">
                  {' '}
                  functional kitchen overhaul
                </span>
              </div>
            </div>
          </div>
          <div className="pallette-row29">
            <div className="pallette-item33">
              <div className="pallette-image54"></div>
              <div className="pallette-details30">
                <span className="pallette-head30 Subheading">
                  {' '}
                  functional kitchen overhaul
                </span>
              </div>
            </div>
            <div className="pallette-item34">
              <div className="pallette-image55"></div>
              <div className="pallette-details31">
                <span className="pallette-head31 Subheading">
                  {' '}
                  functional kitchen overhaul
                </span>
              </div>
            </div>
          </div>
          <div className="pallette-row30">
            <div className="pallette-item35">
              <div className="pallette-image56"></div>
              <div className="pallette-details32">
                <span className="pallette-head32 Subheading">
                  {' '}
                  functional kitchen overhaul
                </span>
              </div>
            </div>
            <div className="pallette-item36">
              <div className="pallette-image57"></div>
              <div className="pallette-details33">
                <span className="pallette-head33 Subheading">
                  {' '}
                  functional kitchen overhaul
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pallette-divider5">
        <span className="pallette-div">CTA</span>
      </div>
      <ContactForm rootClassName="contact-formroot-class-name2"></ContactForm>
      <div className="pallette-cta">
        <div className="pallette-max-width23">
          <div className="pallette-heading-container20">
            <div className="pallette-content9">
              <h1 className="heading SectionHeading pallette-text250">
                meet with us today
              </h1>
              <span className="pallette-text251 copy">
                We’re passionate about bringing your vision to life. Whether
                it’s a remodel or a complete transformation, let’s create a
                space that reflects your unique style.
              </span>
              <Link to="/contact" className="Content bluebutton">
                <span>Schedule a consultation</span>
              </Link>
            </div>
          </div>
          <div className="pallette-image58">
            <img
              alt="image"
              src="/photos/about/office%20edited1-1500w.png"
              className="pallette-image59"
            />
            <div className="pallette-container27"></div>
          </div>
        </div>
      </div>
      <Footer
        link11={
          <Fragment>
            <span className="pallette-text253">Full Floor Remodels</span>
          </Fragment>
        }
        text10={
          <Fragment>
            <span className="pallette-text254">
              Follow us on
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        text14={
          <Fragment>
            <span className="pallette-text255">
              <span className="pallette-text256">Home</span>
              <br></br>
            </span>
          </Fragment>
        }
        text24={
          <Fragment>
            <span className="pallette-text258">About</span>
          </Fragment>
        }
        text34={
          <Fragment>
            <span className="pallette-text259">Contact</span>
          </Fragment>
        }
        text91={
          <Fragment>
            <span className="pallette-text260">
              <span>Custom Home Builds</span>
              <br></br>
            </span>
          </Fragment>
        }
        text111={
          <Fragment>
            <span className="pallette-text263">Kitchen Remodels</span>
          </Fragment>
        }
        text211={
          <Fragment>
            <span className="pallette-text264">Bathroom Remodels</span>
          </Fragment>
        }
        text311={
          <Fragment>
            <span className="pallette-text265">
              <span className="pallette-text266">House Additions</span>
              <br></br>
            </span>
          </Fragment>
        }
        footerId="Footer"
        imageSrc="/logos/logo%20dark-200h.png"
        text1311={
          <Fragment>
            <span className="pallette-text268">
              ©Craft and Forge 2024 All rights reserved
            </span>
          </Fragment>
        }
      ></Footer>
    </div>
  )
}

export default Pallette
