import React, { Fragment } from 'react'
import qs from 'qs'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import ProjectHighlight from '../components/project-highlight'
import ContactForm from '../components/contact-form'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });

  return (
    <div className="home-container10">
      <Helmet>
        <title>Craft & Forge - Premium Home Remodeling</title>
        <meta
          name="description"
          content="Craft &amp; Forge offers high-end remodeling with a seamless process from consultation to completion. Discover expert craftsmanship and stunning transformations."
        />
        <meta
          property="og:title"
          content="Craft &amp; Forge - Premium Home Remodeling"
        />
        <meta
          property="og:description"
          content="Craft &amp; Forge offers high-end remodeling with a seamless process from consultation to completion. Discover expert craftsmanship and stunning transformations."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/133b66a8-437a-4fe1-8938-2c01fd5c558d/c7ed6f96-1e17-4876-a525-ef1747a6b890?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navbar imageSrc1="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"></Navbar>
      <div className="home-hero-fade">
        <div className="home-hero">
          <div className="home-content-container">
            <animate-on-reveal
              animation="fadeInUp"
              duration="1000ms"
              delay="0s"
              direction="normal"
              easing="ease-out"
              iteration="1"
            >
              <div
                data-thq-animate-on-reveal="true"
                className="home-max-width1"
              >
                <div className="home-heading-container1">
                {query.nwredirect ? null : <span className="home-prehead1">Craft &amp; Forge</span>}
                {query.nwredirect ? <span className="home-prehead2">
                    Craft &amp; Forge, previously Northwest unlimited
                  </span> : null }
                  <span className="home-main-title Title">
                    <span>crafting homes,</span>
                    <br></br>
                    <span>forging futures</span>
                  </span>
                  <span className="home-text103">
                    <span>
                      At Craft &amp; Forge Homes, we build homes and lasting
                      relationships. Every project is
                    </span>
                    <br></br>
                    <span>
                      a partnership built on trust, where thoughtful design and
                      meticulous craftsmanship
                    </span>
                    <br></br>
                    <span>
                      {' '}
                      bring your vision to life, creating spaces and connections
                      that last a lifetime.
                    </span>
                  </span>
                  <span className="home-text109">
                    At Craft &amp; Forge Homes, we build homes and lasting
                    relationships. Every project is a partnership built on
                    trust, where thoughtful design and meticulous craftsmanship
                    bring your vision to life, creating spaces and connections
                    that last a lifetime.
                  </span>
                  <a
                    href="#Contact-form"
                    className="home-link bluebutton Content"
                  >
                    <span>Bring your vision to life</span>
                  </a>
                </div>
              </div>
            </animate-on-reveal>
          </div>
        </div>
      </div>
      <div className="home-bathroom-value">
        <div className="home-photo-container1">
          <div className="home-max-width2">
            <div className="home-column1">
              <div className="home-heading-container2">
                <h1 className="home-text111 SectionHeading">
                  <span>your </span>
                  <span>sanctuary</span>
                  <br></br>
                  <span> of luxury</span>
                </h1>
                <span className="home-text116 Content">
                  We design bathrooms that offer more than just beauty—they’re a
                  reflection of your lifestyle. Explore how our expert
                  craftsmanship can transform your bathroom into a sleek,
                  tranquil space.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-kitchen-value">
        <div className="home-photo-container2">
          <div className="home-max-width3">
            <div className="home-column2">
              <div className="home-heading-container3">
                <h1 className="home-text117 SectionHeading">
                  <span>
                    Cook in style,
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <br></br>
                  <span>live in comfort</span>
                </h1>
                <span className="home-text121 Content">
                  Whether you&apos;re entertaining guests or enjoying a quiet
                  family meal, your kitchen should be as refined as your taste.
                  Let Craft &amp; Forge elevate your cooking experience with
                  bold, intelligent designs.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-remodel-types">
        <animate-on-reveal
          animation="fadeIn"
          duration="1000ms"
          delay="0s"
          direction="normal"
          easing="ease-out"
          iteration="1"
        >
          <div
            data-thq-animate-on-reveal="true"
            className="home-heading-container4"
          >
            <span className="home-prehead3 Prehead">Our services</span>
            <h1 className="home-text122 SectionHeading">
              <span>
                transforming
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>every space</span>
            </h1>
          </div>
        </animate-on-reveal>
        <div className="home-types">
          <div className="home-bathrooms">
            <img
              alt="image"
              src="/photos/home/services/225749094_1025523698256360_6876364482089526948_n_edited.jpg"
              className="home-image10"
            />
            <div className="home-container11">
              <span className="home-text126">Bathrooms</span>
              <div className="home-container12"></div>
            </div>
          </div>
          <div className="home-kitchens">
            <img
              alt="image"
              src="/photos/home/services/image2-min-500w.jpeg"
              className="home-image11"
            />
            <div className="home-container13">
              <span className="home-text127">kitchens</span>
              <div className="home-container14"></div>
            </div>
          </div>
          <div className="home-renovations">
            <img
              alt="image"
              src="/photos/home/services/railing%203-500w.png"
              className="home-image12"
            />
            <div className="home-container15">
              <span className="home-text128">
                <span>
                  renovations
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>&amp; Additions</span>
              </span>
              <div className="home-container16"></div>
            </div>
          </div>
          <div className="home-additions">
            <img
              alt="image"
              src="/photos/home/services/img_3627-500w.jpeg"
              className="home-image13"
            />
            <div className="home-container17">
              <span className="home-text132">
                <span>custom</span>
                <br></br>
                <span>homes</span>
              </span>
              <div className="home-container18"></div>
            </div>
          </div>
        </div>
      </div>
      <ProjectHighlight></ProjectHighlight>
      <div className="home-client-process-bubbles">
        <div className="home-img1">
          <img
            alt="image"
            src="/photos/home/process/dylan-gillis-kdeqa3atnby-unsplash.jpg"
            className="home-image14"
          />
          <div className="home-container19"></div>
          <div className="home-container20"></div>
        </div>
        <div className="home-img2">
          <img
            alt="image"
            src="/photos/home/process/pedro-miranda-3qzmbrvceyq-unsplash.jpg"
            className="home-image15"
          />
          <div className="home-container21"></div>
          <div className="home-container22"></div>
        </div>
        <div className="home-img3">
          <img
            alt="image"
            src="/photos/home/process/snapinsta.app_271881287_134163705739032_1045483567002160642_n_1080-min.jpg"
            className="home-image16"
          />
          <div className="home-container23"></div>
          <div className="home-container24"></div>
        </div>
        <div className="home-bg">
          <div className="home-max-width4">
            <div className="home-heading-container5">
              <span className="home-prehead4">Our process</span>
              <h1 className="home-text136 SectionHeading">
                From vision to reality
              </h1>
              <span className="home-text137">
                <span>
                  Our remodeling process blends creativity, expertise, and
                  detailed planning.
                </span>
                <br></br>
                <span>
                  We guide you through every phase, ensuring a beautifully
                  crafted space that exceeds expectations.
                </span>
              </span>
              <span className="home-text141">
                <span>
                  Our remodeling process blends creativity, expertise, and
                  detailed planning. 
                </span>
                <span>
                  We guide you through every phase, ensuring a beautifully
                  crafted space that exceeds expectations.
                </span>
              </span>
            </div>
            <div className="home-timeline-container">
              <div className="home-step11">
                <div className="home-container25">
                  <div className="home-progress01">
                    <img
                      alt="image"
                      src="/icons/process/arrow-down-300h.png"
                      className="home-arrow1"
                    />
                    <div className="home-container26"></div>
                    <span className="home-text144 Subheading">
                      <span>Start</span>
                      <br></br>
                    </span>
                  </div>
                </div>
                <div className="home-container27">
                  <div className="home-container28">
                    <div className="home-container29">
                      <img
                        alt="image"
                        src="/icons/process/videoconference-200h.png"
                        className="home-image17"
                      />
                      <h3 className="home-text147 Subheading">
                        Initial Discovery Call
                      </h3>
                    </div>
                  </div>
                  <span className="home-text148">
                    <span className="Content">
                      We start with a virtual consultation to understand your
                      remodeling goals, budget, and timeline, ensuring a perfect
                      fit for your project.
                    </span>
                    <br className="Content"></br>
                    <br className="Content"></br>
                    <span className="Content">This step includes:</span>
                    <br className="Content"></br>
                    <span className="home-text154 Content">
                      Needs Assessment
                    </span>
                    <span className="home-text155 Content">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className="Content">
                      - Understanding your goals to tailor our services.
                    </span>
                    <br className="Content"></br>
                    <span className="home-text158 Content">
                      Budget Discussion
                    </span>
                    <span className="Content">
                      {' '}
                      - Aligning expectations to avoid surprises.
                    </span>
                    <br className="Content"></br>
                    <span className="home-text161 Content">
                      Preliminary Ideas
                    </span>
                    <span className="Content">
                      {' '}
                      - Sharing initial concepts to inspire alignment.
                    </span>
                    <br className="ContentSmall"></br>
                  </span>
                  <div className="home-container30">
                    <img
                      alt="image"
                      src="/icons/process/arrow-left-200h.png"
                      className="home-image18"
                    />
                  </div>
                </div>
              </div>
              <div className="home-step12">
                <div className="home-container31">
                  <div className="home-progress02">
                    <img
                      alt="image"
                      src="/icons/process/arrow-down-300h.png"
                      className="home-arrow2"
                    />
                  </div>
                </div>
                <div className="home-container32">
                  <div className="home-container33">
                    <div className="home-container34">
                      <img
                        alt="image"
                        src="/icons/process/land-layer-location-200h.png"
                        className="home-image19"
                      />
                      <h3 className="home-text164 Subheading">
                        On-site assessment
                      </h3>
                    </div>
                  </div>
                  <span className="home-text165">
                    <span className="Content">
                      We conduct a detailed site assessment, taking precise
                      measurements and discussing material options to refine the
                      project scope.
                    </span>
                    <br className="Content"></br>
                    <br className="Content"></br>
                    <span className="Content">This step includes:</span>
                    <br className="Content"></br>
                    <span className="home-text171">Material Options</span>
                    <span>
                      {' '}
                      - Presenting tailored material recommendations.
                    </span>
                    <br className="Content"></br>
                    <span className="home-text174">Scope Refinement</span>
                    <span> - Clarifying the project scope and priorities.</span>
                    <br className="Content"></br>
                    <span className="home-text177">Timeline Estimate</span>
                    <span> - Providing a preliminary timeline.</span>
                    <br className="ContentSmall"></br>
                  </span>
                  <div className="home-container35">
                    <img
                      alt="image"
                      src="/icons/process/arrow-right-200h.png"
                      className="home-image20"
                    />
                  </div>
                  <div className="home-container36">
                    <img
                      alt="image"
                      src="/icons/process/arrow-left-200h.png"
                      className="home-image21"
                    />
                  </div>
                </div>
              </div>
              <div className="home-step13">
                <div className="home-container37">
                  <div className="home-progress03">
                    <img
                      alt="image"
                      src="/icons/process/arrow-down-300h.png"
                      className="home-arrow3"
                    />
                  </div>
                </div>
                <div className="home-container38">
                  <div className="home-container39">
                    <div className="home-container40">
                      <img
                        alt="image"
                        src="/icons/process/plan-strategy-200h.png"
                        className="home-image22"
                      />
                      <h3 className="home-text180 Subheading">
                        Tailored solutions presentation
                      </h3>
                    </div>
                  </div>
                  <span className="home-text181">
                    <span>
                      We breakdown costs and detail each proposal to avoid
                      surprises, helping you make an informed decision aligned
                      with your vision.
                    </span>
                    <br></br>
                    <br></br>
                    <span>This step includes:</span>
                    <br></br>
                    <span className="home-text187">Custom Proposals</span>
                    <span> - Offering options tailored to your needs.</span>
                    <br></br>
                    <span className="home-text190">Cost Breakdown</span>
                    <span> - Providing transparent pricing details.</span>
                    <br></br>
                    <span className="home-text193">Scope Flexibility</span>
                    <span> - Adapting proposals to your preferences.</span>
                    <br className="ContentSmall"></br>
                  </span>
                  <div className="home-container41">
                    <img
                      alt="image"
                      src="/icons/process/arrow-left-200h.png"
                      className="home-image23"
                    />
                  </div>
                </div>
              </div>
              <div className="home-step14">
                <div className="home-container42">
                  <div className="home-progress04">
                    <img
                      alt="image"
                      src="/icons/process/arrow-down-300h.png"
                      className="home-arrow4"
                    />
                  </div>
                </div>
                <div className="home-container43">
                  <div className="home-container44">
                    <div className="home-container45">
                      <img
                        alt="image"
                        src="/icons/process/to-do-200h.png"
                        className="home-image24"
                      />
                      <h3 className="home-text196 Subheading">
                        Strategic Project Planning 
                      </h3>
                    </div>
                  </div>
                  <span className="home-text197">
                    <span>
                      Finalizing project details, we coordinate with architects
                      and engineers, create a detailed timeline, and handle all
                      project logistics.
                    </span>
                    <br></br>
                    <br></br>
                    <span>This step includes:</span>
                    <br></br>
                    <span className="home-text203">Comprehensive Timeline</span>
                    <span> - Outlining each project phase.</span>
                    <br></br>
                    <span className="home-text206">Permitting</span>
                    <span> - Managing all permits and regulations.</span>
                    <br></br>
                    <span className="home-text209">Quality Materials</span>
                    <span> - Sourcing high-quality materials.</span>
                    <br className="ContentSmall"></br>
                  </span>
                  <div className="home-container46">
                    <img
                      alt="image"
                      src="/icons/process/arrow-right-200h.png"
                      className="home-image25"
                    />
                  </div>
                  <div className="home-container47">
                    <img
                      alt="image"
                      src="/icons/process/arrow-left-200h.png"
                      className="home-image26"
                    />
                  </div>
                </div>
              </div>
              <div className="home-step15">
                <div className="home-container48">
                  <div className="home-progress05">
                    <img
                      alt="image"
                      src="/icons/process/arrow-down-300h.png"
                      className="home-arrow5"
                    />
                  </div>
                </div>
                <div className="home-container49">
                  <div className="home-container50">
                    <div className="home-container51">
                      <img
                        alt="image"
                        src="/icons/process/builder-200h.png"
                        className="home-image27"
                      />
                      <h3 className="home-text212 Subheading">
                        Execution Phase
                      </h3>
                    </div>
                  </div>
                  <span className="home-text213">
                    <span>
                      Our skilled craftsmen bring your vision to life with
                      precision and care, maintaining open communication and
                      upholding
                    </span>
                    <br></br>
                    <span>the highest quality standards.</span>
                    <br></br>
                    <br></br>
                    <span>This step includes:</span>
                    <br></br>
                    <span className="home-text221">Quality Control</span>
                    <span> - Maintaining high standards throughout.</span>
                    <br></br>
                    <span className="home-text224">Progress Updates</span>
                    <span> - Keeping you informed and involved.</span>
                    <br></br>
                    <span className="home-text227">Constant Collaboration</span>
                    <span> - Welcoming feedback at every stage.</span>
                    <br className="ContentSmall"></br>
                  </span>
                  <div className="home-container52">
                    <img
                      alt="image"
                      src="/icons/process/arrow-left-200h.png"
                      className="home-image28"
                    />
                  </div>
                </div>
              </div>
              <div className="home-step16">
                <div className="home-container53">
                  <div className="home-progress06">
                    <div className="home-container54"></div>
                    <span className="home-text230 Subheading">
                      <span>Finish</span>
                      <br></br>
                    </span>
                  </div>
                </div>
                <div className="home-container55">
                  <div className="home-container56">
                    <div className="home-container57">
                      <img
                        alt="image"
                        src="/icons/process/memo-circle-check-200h.png"
                        className="home-image29"
                      />
                      <h3 className="home-text233 Subheading">
                        Final reveal &amp; review
                      </h3>
                    </div>
                  </div>
                  <span className="home-text234">
                    <span>
                      Upon completion, we conduct a final walkthrough to ensure
                      satisfaction, offer a comprehensive warranty, and provide
                      ongoing support.
                    </span>
                    <br></br>
                    <br></br>
                    <span>This step includes:</span>
                    <br></br>
                    <span className="home-text240">Thorough Walkthrough</span>
                    <span> - Ensuring your complete satisfaction.</span>
                    <br></br>
                    <span className="home-text243">Warranty &amp; Support</span>
                    <span> - Providing ongoing peace of mind.</span>
                    <br></br>
                    <span className="home-text246">Follow-Up</span>
                    <span>
                      {' '}
                      - Conducting check-ins to ensure lasting satisfaction.
                    </span>
                    <br className="ContentSmall"></br>
                  </span>
                  <div className="home-container58">
                    <img
                      alt="image"
                      src="/icons/process/arrow-right-200h.png"
                      className="home-image30"
                    />
                  </div>
                  <div className="home-container59">
                    <img
                      alt="image"
                      src="/icons/process/arrow-left-200h.png"
                      className="home-image31"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-meet-the-founder-page-cut">
        <div className="home-gradient-container1"></div>
        <div className="home-blur-container">
          <div className="home-max-width5">
            <div className="home-container60">
              <div className="home-heading-container6">
                <span className="home-prehead5">Our founder</span>
                <h1 className="home-text249 SectionHeading">
                  <span>Crafting a Legacy</span>
                  <br></br>
                  <span>of excellence</span>
                </h1>
                <h1 className="home-text253 SectionHeading">
                  <span>
                    Crafting a Legacy
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>of excellence</span>
                </h1>
              </div>
              <div className="home-content">
                <div className="home-line"></div>
                <div className="home-section-title">
                  <h2 className="home-text256">
                    <span className="Subheading">Dayne Richards</span>
                    <br></br>
                  </h2>
                  <p className="home-text259 Content">
                    <span>
                      With over 20 years of experience in the remodeling
                      industry, Dayne founded Craft &amp; Forge to bring a new
                      level of excellence to home renovation. His dedication to
                      modern design and meticulous craftsmanship has transformed
                      countless homes into architectural masterpieces.
                    </span>
                    <br></br>
                    <br></br>
                    <span>
                      &quot;For me, home has always been a place where my values
                      need to be represented. Having a beautiful home could be
                      chalked up to vanity, but it so much more than that. We
                      spend so much time, energy, and money on our homes. Why
                      shouldn’t it be something that truly reflects us and is a
                      place we are proud of.&quot;
                    </span>
                    <br></br>
                  </p>
                </div>
              </div>
            </div>
            <img
              alt="Craft &amp; Forge Features"
              src="/photos/home/dayne%20head%20mixed2-1000w.png"
              className="home-image1"
            />
          </div>
        </div>
        <div className="home-gradient-container2"></div>
      </div>
      <ContactForm
        contactFormId="Contact-form"
        rootClassName="contact-formroot-class-name"
      ></ContactForm>
      <Footer
        link11={
          <Fragment>
            <span className="home-text265">Full Floor Remodels</span>
          </Fragment>
        }
        text10={
          <Fragment>
            <span className="home-text266">
              Follow us on
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        text14={
          <Fragment>
            <span className="home-text267">
              <span className="home-text268">Home</span>
              <br></br>
            </span>
          </Fragment>
        }
        text24={
          <Fragment>
            <span className="home-text270">About</span>
          </Fragment>
        }
        text34={
          <Fragment>
            <span className="home-text271">Contact</span>
          </Fragment>
        }
        text91={
          <Fragment>
            <span className="home-text272">
              <span>Custom Home Builds</span>
              <br></br>
            </span>
          </Fragment>
        }
        text111={
          <Fragment>
            <span className="home-text275">Kitchen Remodels</span>
          </Fragment>
        }
        text211={
          <Fragment>
            <span className="home-text276">Bathroom Remodels</span>
          </Fragment>
        }
        text311={
          <Fragment>
            <span className="home-text277">
              <span className="home-text278">House Additions</span>
              <br></br>
            </span>
          </Fragment>
        }
        footerId="Footer"
        imageSrc="/logos/logo%20dark-200h.png"
        text1311={
          <Fragment>
            <span className="home-text280">
              ©Craft and Forge 2024 All rights reserved
            </span>
          </Fragment>
        }
      ></Footer>
    </div>
  )
}

export default Home
