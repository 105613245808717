import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './navbar.css'

const Navbar = (props) => {
  scrollTo({top:0, left:0, behavior:'instant'});
  let selectedStyle = {
    color:'white',
    'border-bottom': 'solid 2px #115fff'
  }

  const [menuVisible, setMenuVisible] = useState(false)
  const handleClickOutside = (event) => {
    if (!event.target.closest('.navbar-burger-menu1') && !event.target.closest('.navbar-mobile-menu')) {
      setMenuVisible(false);
    }
  };

  useEffect(() => {
    if (menuVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuVisible]);

  return (
    <nav data-role="Header" className={`navbar-navbar ${props.rootClassName} `}>
      <nav data-role="Header" className="navbar-container">
        <div className="navbar-right1">
          <img
            alt="image"
            src="/logos/craft-and-forge-homes-logotype-white-rgb-900px-w-72ppi.png"
            className="navbar-image"
          />
        </div>
        <div className="navbar-right2">
          <div className="navbar-links-container1">
            <Link to="/" className="navbar-navlink1 Nav" style={location.pathname == '/' ? selectedStyle : null}>
              Home
            </Link>
            <Link to="/about" className="navbar-navlink2 Nav" style={location.pathname == '/about' ? selectedStyle : null}>
              about
            </Link>
            <Link to="/contact" className="bluebutton Content">
              <span>Request a quote</span>
            </Link>
          </div>
        </div>
        <div
          onClick={() => setMenuVisible(!menuVisible)}
          className="navbar-burger-menu1"
        >
          <svg viewBox="0 0 1024 1024" className="navbar-burger-menu2">
            <path d="M810.667 725.333h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
            <path d="M810.667 426.667h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
            <path d="M810.667 128h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
          </svg>
        </div>
        {menuVisible && (
          <div className="navbar-mobile-menu">
            <div className="navbar-nav">
              <div className="navbar-right-side">
                <div className="navbar-links-container2">
                  <Link to="/" className="navbar-navlink4 Nav">
                    Home
                  </Link>
                  <Link to="/contact" className="navbar-navlink5 Nav">
                    Contact
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </nav>
    </nav>
  )
}

Navbar.defaultProps = {
  rootClassName: '',
  imageSrc: '82ae618e-a5a6-4458-b732-8c1b688d1d0f',
}

Navbar.propTypes = {
  rootClassName: PropTypes.string,
  imageSrc: PropTypes.string,
}

export default Navbar
